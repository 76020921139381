import { configureStore, createSlice } from '@reduxjs/toolkit';
// import React from 'react';


const initialState = {
  cities:[],
};

const DataSlice = createSlice({
  name: 'Data',
  initialState,
  reducers: {
    setCities: (state, action) => {
      console.log(action.payload);
      state.cities = action.payload;
    },
  
  },
});

export const { setCities } = DataSlice.actions;

export const  store = configureStore({
  reducer: DataSlice.reducer,
});

