import React, { useRef, Component, useContext } from "react";
import "./navbar.css";
import { useState } from "react";
import { useEffect } from "react";
import { redirect, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import { Url } from "../../..";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import { enCA } from "date-fns/locale";
import { format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import Select , { StylesConfig } from 'react-select';
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import { searchLoading } from "../../../App";
function SearchBar() {
  registerLocale("en-CA", enCA);
  const [cities, setCities] = useState([]);
  const [city, setCity] = useState(localStorage.getItem('city')?localStorage.getItem('city'):"");
  const [checkIn, setcheckIn] = useState(localStorage.getItem('checkIn')&&localStorage.getItem('checkIn')!=='null'?new Date(localStorage.getItem('checkIn')):null);
  const [checkOut, setcheckOut] = useState(localStorage.getItem('checkOut')&&localStorage.getItem('checkOut')!=='null'?new Date(localStorage.getItem('checkOut')):null);
  const [guests, setguests] = useState(localStorage.getItem('guest')?parseInt(localStorage.getItem('guest')):"");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [mobile, setmobile] = useState(false);
  
  const [loadingBtn, setLoadingBtn] = useState(false);
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);
  const [errorContext, setErrorContext] = useState("");
  const location = useLocation();
  const inputRef = useRef(null);
  const { setSarchload , sarchload , setDataSearch } = useContext(searchLoading);
  useEffect(() => {
    setTimeout(async () => {
      const { data } = await Axios.get(Url + `/api/dropdown/city`);
      setCities(data.cities);
    }, 1000);
  }, []);
  console.log(format(new Date(), "mm/dd/yyyy"));
  const handleChange = ({ currentTarget: input }) => {
    console.log(input.value);
    if (input.name === "city") setCity(input.value);
    else if (input.name === "checkIn") setcheckIn(input.value);
    else if (input.name === "checkOut") setcheckOut(input.value);
    else {
      if (input.value >= 0) {
        setguests(input.value);
        localStorage.setItem('guest', input.value);
      }
    }
  };
 const handleChangeCity = (e) => {
 console.log(e);
 if(e){

   setCity(e.value)
   localStorage.setItem('city', e.value)
   let obj = {
    label:options?.filter(d => d.value == e.value)[0]?.label,
    value:e.value
   }
   localStorage.setItem('cityObj',JSON.stringify(obj) )
   setselectObj(obj)
 }else{
  setCity("")
  setselectObj(null)
  localStorage.setItem('city', "")
  localStorage.setItem('cityObj', "")

 }
}
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: 'none',
      borderRadius: '4px',
      zIndex: '99',
      // padding: '5px',
      background: state.isFocused ? 'white' : 'white',
      boxShadow: state.isFocused ? 'none' : 'none',
      '& .css-1nmdiq5-menu':{
        zIndex: '99',
      },
      '&:hover': {
        // border: '1px solid #aaa',
      }
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'lightblue' : 'white',
      '&:hover': {
        backgroundColor: 'lightgray',
      }
    })
  };
  const options = cities.map(city => {
    return {
      value:city._id ,
       label: city.name 
    }
  })
    

  const [selectObj, setselectObj] = useState(localStorage.getItem('cityObj')?JSON.parse(localStorage.getItem('cityObj')):null)

  localStorage.setItem("screenWidth", screenWidth);
  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
      localStorage.setItem("screenWidth", screenWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function handlePaste(event) {
    event.preventDefault();
  }

  const handleKeyDown = (event) => {
    if (event.key === "-" || event.key === "+") {
      event.preventDefault();
    }
    if (event.key === "e") {
      event.preventDefault();
    }
  };

  const handleSearch = async () => {
    try {
        
      if (
        city.length > 0 ||
        checkIn !== null ||
        checkOut !== null ||
        guests.length > 0
      ) {
        console.log(new Date(checkIn).toLocaleDateString("en-CA"));
        const date = new Date(checkIn);
        let month = "" + (date.getMonth() + 1);
        let day = "" + date.getDate();
        let year = date.getFullYear();
        console.log(year - month - day);
        let checkin = new Date(checkIn).toLocaleDateString("en-CA");
        let checkout = new Date(checkOut).toLocaleDateString("en-CA");
        setLoadingBtn(true);
        // loading = true;
        setSarchload(true);
        const { data } = await Axios.get(
          Url +
            `/api/search?city=` +
            city +
            "&startDate=" +
            `${checkIn ? checkin : new Date().toLocaleDateString("en-CA") }` +
            "&endDate=" +
            `${checkOut ? checkout : new Date().toLocaleDateString("en-CA") }` +
            "&guests=" +
            guests +
            "&pageSize=" +
            8 +
            "&pageNumber=" +
            1
        );

        if (data.count > 0) {
          setLoadingBtn(false);
          setShowError(false);
          setErrorContext("");
          let cityName = "";
          if (city !== "") {
            setDataSearch(data)
            cityName = cities.filter((c) => c._id === city);
            navigate("/search", {
              state: {
                data: data,
                city: city,
                startDate: checkIn ? checkin : new Date().toLocaleDateString("en-CA"),
                endDate: checkOut ? checkout : new Date().toLocaleDateString("en-CA"),
                guests: guests,
                cityName: cityName[0].name,
              },
            });
            setSarchload(false);

          } else {
            setDataSearch(data)
            cityName = cities.filter((c) => c._id === city);
            navigate("/search", {
              state: {
                data: data,
                city: city,
                startDate: checkIn ? checkin : new Date().toLocaleDateString("en-CA"),
                endDate: checkOut ? checkout : new Date().toLocaleDateString("en-CA"),
                guests: guests,
                cityName: "",
              },
            });
            setSarchload(false);
          }
        } else {
          setLoadingBtn(false);
          setShowError(true);
          setDataSearch({})
          setErrorContext("No results found");
          setSarchload(false);
        }
      } else {
        setShowError(true);
        setLoadingBtn(false);
        setErrorContext("All fields are empty");
        setSarchload(false);
      }
    } catch (error) {
      setLoadingBtn(false);
      toast.error(error.response?.data?.message);
      setSarchload(false);
    }
  };

  const mobileSearch = () => {
    const mo = !mobile;
    setmobile(mo);
  };
  return screenWidth >= 768 ? (
    <div className="nav-search-container">
      <div className="row bac-sec">
        <div className="container chalets-header-ctr">
          <h1 className="chalets-header">
            Find the best chalets across Lebanon
          </h1>
        </div>
        <button
          className={
            mobile ? "nav-search-btn-min d-none" : "nav-search-btn-min"
          }
          disabled={loadingBtn}
          onClick={mobileSearch}
        >
          {" "}
          {loadingBtn?<CircularProgress style={{ color: "#fff" }} /> : "Search"}
        </button>
        <div className={mobile ? "navbar-mobile" : "d-none"}>
          <div className="bi-x " onClick={mobileSearch}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="currentColor"
              className="bi bi-x"
              viewBox="0 0 16 16"
            >
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
            {showError == true && (
              <p className="error-mobile">{errorContext}</p>
            )}
          </div>
          <div className="conatainer">
            <div className="form-group ">
              <label htmlFor="exampleInputEmail1" className="bolder">
                Where
              </label>
              <Select
              
            className="search-input"
            classNamePrefix="select"
            // defaultValue={colourOptions[0]}
            placeholder="Search destinations"
            // isDisabled={isDisabled}
            // isLoading={isLoading}
            value={selectObj}
            isClearable={true}
            styles={customStyles}
            // isRtl={isRtl}
            isSearchable={true}
            name="city"
            onChange={handleChangeCity}
            options={options}
          />
              {/* <select
                name="city"
                className="search-input "
                aria-label="Default select example"
                onChange={handleChange}
              >
                <option value="">Search destinations</option>
                {cities.map((c) => (
                  <option value={c._id} key={c._id}>
                    {c.name}
                  </option>
                ))}
              </select> */}
            </div>

            <div className="form-group ">
              <label htmlFor="exampleInputEmail1" className="bolder">
                Check in
              </label>
              {/* <DatePicker 
              selected={checkIn} 
              onChange={handleChange} 
              name="checkIn"
              className="search-input"
              id="exampleInputEmail1"
              placeholder="Add dates"
              /> */}
              <DatePicker
                selected={checkIn}
                onChange={(date) =>{ setcheckIn(date) 
                localStorage.setItem('checkIn', date  )
                }}
                locale="en-CA"
                className="search-input"
                // showIcon
                isClearable
                showMonthDropdown
                showYearDropdown
                placeholderText="mm/dd/yyyy"
                // showTimeSelect
                dateFormat="yyyy/MM/dd"
              />
              {/* <input
                name="checkIn"
                type="date"
                className="search-input"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Add dates"
                onChange={handleChange}
                ref={inputRef}
                onFocus={() =>console.log(inputRef.current)}
                
              /> */}
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1" className="bolder">
                {" "}
                Check out
              </label>
              <DatePicker
                selected={checkOut}
                onChange={(date) =>{ setcheckOut(date)
                localStorage.setItem('checkOut', date)
                }}
                locale="en-CA"
                className="search-input"
                // showIcon
                isClearable
                showMonthDropdown
                showYearDropdown
                placeholderText="mm/dd/yyyy"
                // showTimeSelect
                dateFormat="yyyy/MM/dd"
              />
              {/* <input
                name="checkOut"
                type="date"
                className="search-input"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Add dates"
                onChange={handleChange}
              /> */}
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1" className="bolder">
                {" "}
                Guests
              </label>
              <input
                onKeyDown={handleKeyDown}
                name="guests"
                type="number"
                min={0}
                value={guests}
                onPaste={handlePaste}
                className="search-input"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Add guests"
                onChange={handleChange}
              />
            </div>
            <div className="text-center mt-4">
              <button disabled={loadingBtn} className="moblie-btn" onClick={handleSearch}>
                {" "}
                {loadingBtn? <CircularProgress style={{ color: "#fff" }} />:" Search"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container search">
        <div className="row search-row">
          <div className={screenWidth < 992 ? "col-4 p-0" : " col-3"}>
            <label htmlFor="exampleInputEmail1" className="bolder">
              Where
            </label>
            <Select
            className="search-input"
            classNamePrefix="select"
            // defaultValue={colourOptions[0]}
            placeholder="Search destinations"
            // isDisabled={isDisabled}
            // isLoading={isLoading}
            value={selectObj}
            isClearable={true}
            styles={customStyles}
            // isRtl={isRtl}
            isSearchable={true}
            name="city"
            onChange={handleChangeCity}
            options={options}
          />
            {/* <select
              name="city"
              className="search-input search-dest-width"
              aria-label="Default select example"
              onChange={handleChange}
            >
              <option value="">Search destinations</option>
              {cities.map((c) => (
                <option value={c._id} key={c._id}>
                  {c.name}
                </option>
              ))}
            </select> */}
          </div>

          <div className={screenWidth < 992 ? "col-2 p-0" : " col-2"}>
            <hr id="hr" />
            <label htmlFor="exampleInputEmail1" className="bolder">
              {" "}
              Check in
            </label>
            <DatePicker
              selected={checkIn}
              onChange={(date) => {setcheckIn(date)
                localStorage.setItem('checkIn', date )
              }}
              locale="en-CA"
              className="search-input"
              // customInput={<input type="date" />}
              // showIcon
              isClearable
              showMonthDropdown
              showYearDropdown
              placeholderText="mm/dd/yyyy"
              // showTimeSelect
              dateFormat="yyyy/MM/dd"
            />
            {/* <input
              name="checkIn"
              type="date"
              
              className="search-input"

              
              placeholder="Add dates"
              onChange={handleChange}
              onFocus={() =>inputRef.current.focus()}
            /> */}
          </div>

          <div className={screenWidth < 992 ? "col-2 p-0" : " col-2"}>
            <hr id="hr" />
            <label htmlFor="exampleInputEmail1" className="bolder">
              {" "}
              Check out
            </label>
            <DatePicker
              selected={checkOut}
              onChange={(date) =>{ setcheckOut(date)
                localStorage.setItem('checkOut', date)
              }}
              locale="en-CA"
              className="search-input"
              // showIcon
              isClearable
              showMonthDropdown
              showYearDropdown
              placeholderText="mm/dd/yyyy"
              // showTimeSelect
              dateFormat="yyyy/MM/dd"
            />
            {/* <input
              name="checkOut"
              type="date"
              className="search-input"
            
              
              placeholder="Add dates"
              onChange={handleChange}
            /> */}
          </div>

          <div className={screenWidth < 992 ? "col-2 p-0" : " col-3"}>
            <hr id="hr" />
            <label htmlFor="exampleInputEmail1" className="bolder">
              {" "}
              Guests
            </label>
            <input
              name="guests"
              onKeyDown={handleKeyDown}
              type="number"
              min={0}
              value={guests}
              onPaste={handlePaste}
              className="search-input"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Add guests"
              onChange={handleChange}
            />
          </div>
          <div className="col-2">
            <button disabled={loadingBtn} className="nav-search-btn" onClick={handleSearch}>
              {" "}
              {loadingBtn? <CircularProgress style={{ color: "#fff" }} />:" Search"}
            </button>
          </div>
        </div>
        {showError == true && <p className="error">{errorContext}</p>}
      </div>
    </div>
  ) : (
    <div className=" mobile-container">
      <div className="mobile-img">
        <div>
          <h1 className="mobile-chalets-header" style={{ textAlign: "center" }}>
            Find the best chalets across Lebanon
          </h1>
        </div>
      </div>

      <div className="mobile-form-container">
        <div className="w-100 labelInputContainer">
          <label>Where</label>
          <br />
          <Select
            className="search-input"
            classNamePrefix="select"
            // defaultValue={colourOptions[0]}
            placeholder="Search destinations"
            // isDisabled={isDisabled}
            // isLoading={isLoading}
            value={selectObj}
            isClearable={true}
            styles={customStyles}
            // isRtl={isRtl}
            isSearchable={true}
            name="city"
            onChange={handleChangeCity}
            options={options}
          />
          {/* <select
            name="city"
            className="mobile-input"
            aria-label="Default select example"
            onChange={handleChange}
          >
            <option value="">Search destinations</option>
            {cities.map((c) => (
              <option value={c._id} key={c._id}>
                {c.name}
              </option>
            ))}
          </select> */}
        </div>

        <div className="checks-container w-100 ">
          <div className="check1 labelInputContainer">
            <label>Check In</label>
            <br />
            <DatePicker
              selected={checkIn}
              onChange={(date) => {setcheckIn(date)
                localStorage.setItem('checkIn', date  )
              }}
              locale="en-CA"
              className="search-input pl-2 border-0 shadow-none"
              // showIcon
              isClearable
              
              showMonthDropdown
              showYearDropdown
              placeholderText="mm/dd/yyyy"
              // showTimeSelect
              dateFormat="yyyy/MM/dd"
              withPortal
              onFocus={(e) =>{ e.target.readOnly = true 
                console.log(e)}}
            />
            {/* <input
              placeholder="Add dates"
              className="mobile-input"
                type="date"
                id="myDate"
                name="checkIn"
                onChange={handleChange}
                /> */}
          </div>
          <div className="check2 labelInputContainer">
            <label>Check Out</label>
            <br />
            <DatePicker
              selected={checkOut}
              onChange={(date) =>{ setcheckOut(date)
                localStorage.setItem('checkOut', date)
              }}
              locale="en-CA"
              className="search-input pl-2 border-0 shadow-none"
              // showIcon
              isClearable
              showMonthDropdown
              showYearDropdown
              placeholderText="mm/dd/yyyy"
              // showTimeSelect
              dateFormat="yyyy/MM/dd"
              withPortal
              onFocus={(e) => e.target.readOnly = true}
            />
            {/* <input
                placeholder="Add dates"
                id="myDate"
                name="checkOut"
                type="date"

                onChange={handleChange}
                className="mobile-input"
                /> */}
          </div>
        </div>

        <div className="w-100 labelInputContainer">
          <label>Guests</label>
          <br />
          <input
            onKeyDown={handleKeyDown}
            name="guests"
            min={0}
            value={guests}
            onPaste={handlePaste}
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            placeholder="Add guests"
            onChange={handleChange}
            className="mobile-input"
            type="number"
            style={{border:"none"}}
          />
        </div>
        <button disabled={loadingBtn} onClick={handleSearch} className="mobile-search-button">
        {loadingBtn? <CircularProgress style={{ color: "#fff" }} />:" Search"}
        </button>
      </div>

      {showError == true && <p className="error-mobile" style={{top:10}}>{errorContext}</p>}
    </div>
  );
}
export default SearchBar;
