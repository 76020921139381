const SearchFooter = () => {
  return (
    <div className="search-footer list">
      <div className="footer1-ctr">
        <div className="list-container">
          <div className="row">
            <h3 className="h3-list-property sec-text text-center">
              List your property on Chalet Lebanon and open your door to
              beautiful guests!
            </h3>
          </div>
          <form action="/add">
          <div className="row list-btn">
            <button className="list-property" type="submit">List your property</button>
           
          </div>
          </form>
        </div>
      </div>
      <div className="search-container">
        <div className="row  footer2-ctr container">
          <div className="col-sm-12 col-md-4  get-offers-ctr">
            <h5 className="get-offers sec-text">
              Get special offers, travel inspiration, and more from Chalet
              Lebanon
            </h5>
          </div>
          <div style={{maxWidth:"90%"}} className="col-sm-12 col-md-6 sreach">
            <div className="input-group email-input">
              <input
                type="text"
                className="form-control full-email-input"
                placeholder="Email Address"
              
              />
              <div className="input-group-append">
                <button className="search-btn full-email-input" type="button">
                  Subscribe
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SearchFooter;
