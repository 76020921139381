import logo from './logo.svg';
import './App.css';
import React, { createContext, useState } from 'react';
import { Route,Routes } from 'react-router-dom';
import Navbar from './components/common/navbar/navbar'
import Footer from './components/common/footer/main'
import Home from './components/common/views/home';
import Properties from './components/common/views/properties/properties';
import Property from './components/common/views/propertyDetails/propetry';
import AddProperty from './components/common/views/addProperty/add';
import FAQ from './components/common/views/FAQ/FAQ';
import Search from './components/common/views/search';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const searchLoading = createContext();
function App() {
  const [sarchload, setSarchload] = useState(false)
  const [dataSearch, setDataSearch] = useState({})

  const removeLocalStorage = () => {
    localStorage.removeItem('city')
    localStorage.removeItem('cityObj')
    localStorage.removeItem('checkIn')
    localStorage.removeItem('checkOut')
    localStorage.removeItem('guest')

  }
  return (
    <React.Fragment>
 
        <Navbar/>
        <ToastContainer/>
        <searchLoading.Provider value={{sarchload  , setSarchload , dataSearch , setDataSearch , removeLocalStorage}}>
        <Routes>
        <Route path={"/"}  exact element={<Home />}/>
        <Route path={"/add"} exact element={<AddProperty />} />
        <Route path={"/FAQ"} exact element={<FAQ />}/>
        <Route path={"/properties/:type/:propertyName"} exact  element={<Properties />}/>
        <Route path={"/:typee/:propertyName"} exact element={<Property />}/>
        <Route path={"/search"} exact element={<Search />}/>
        </Routes>
        </searchLoading.Provider>
        <Footer/>
     </React.Fragment>
  );
}

export default App;
