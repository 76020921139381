import React, { useContext, useLayoutEffect } from "react";
import { useState, useRef, useCallback } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import SearchBar from "../../navbar/searchbar";
import Axios from "axios";
import "./properties.css";
import { Dropdown } from "react-bootstrap";
import Card from "../../card/card";
import Slider from "@mui/material/Slider";
import SearchFooter from "../../footer/searchFooter";
import GoogleMap from "./map";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { FreeMode } from "swiper";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import { Url } from "../../../..";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import ReactPaginate from "react-paginate";
import Pagination from '@mui/material/Pagination';
import { Typography } from "@mui/material";
import { IoWarningOutline } from "react-icons/io5";
import { toast } from "react-toastify";
import Image from "../../../../images/cartempty.png";
import { searchLoading } from "../../../../App";
function Properties() {
  const [cities, setCities] = useState([]);
  const [selectedFilters, setsSelectedFilters] = useState([]);
  const [currentFilter, setscurrentFilter] = useState("Rooms & Beds");
  const [minPrice, setminPrice] = useState(0);
  const [maxPrice, setmaxPrice] = useState(900);
  const [sliderValue, setsliderValue] = useState([100,300]);
  const [populer, setpopuler] = useState([]);
  const [properties, setproperties] = useState([]);
  const [bedrooms, setbedrooms] = useState(1);
  const [beds, setbeds] = useState(1);
  const [bathrooms, setbathrooms] = useState(1);
  const [city, setCity] = useState("");
  const [page, setpage] = useState(1);
  const [pageSize, setpageSize] = useState(6);
  const [count, setCount] = useState(0);
  const [cityCount, setCityCount] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingProperties, setLoadingProperties] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [places, setMap] = useState([]);
  const [display, setDisplay] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [categories, setcategories] = useState([]);
  const [isPageChange, setisPageChange] = useState(false)
  const [loadingPage, setloadingPage] = useState(false)
  const [loadingSort, setloadingSort] = useState(false)
  const { removeLocalStorage } = useContext(searchLoading);
  // const [loading, setLoading] = useState(false);
  const [filterAcction, setfilterAcction] = useState(false)
  const [defaultProps] = useState({
    center: {
      lat: 33.8924858,
      lng: 35.7457829,
    },
    zoom: 11,
  });
 const { type , propertyName } = useParams();
  const location = useLocation();
  const getDataOfCategoriesAndCities = async() => {
    setLoading(true);
    const { data } = await Axios.get(Url + "/api/home");
    console.log(data.cities);
    localStorage.setItem("cities", JSON.stringify(data.cities));
    setCities(data.cities);
    setcategories(data.categories);
    setLoading(false);
  };
  const id = location.state?.id
    ? location.state?.id
    : type === "city"
    ? cities?.filter(
        (c) =>
          c.name?.trim().replaceAll(/\s+/g, "-")?.replaceAll("---", "-") ==
          propertyName?.trim().replaceAll(/\s+/g, "-")?.replaceAll("---", "-")
      )[0]?._id
    : categories.filter(
        (c) =>
          c.name?.trim().replaceAll(/\s+/g, "-")?.replaceAll("---", "-") ==
          propertyName?.trim().replaceAll(/\s+/g, "-")?.replaceAll("---", "-")
      )[0]?._id;
      console.log(cities);
      console.log(propertyName?.trim().replaceAll(/\s+/g, "-")?.replaceAll("---", "-"));
  console.log(
    cities?.filter(
      (c) =>
        c.name?.trim().replaceAll(/\s+/g, "-")?.replaceAll("---", "-") ==
        propertyName?.trim().replaceAll(/\s+/g, "-")?.replaceAll("---", "-")
    )[0]?._id
  );
  console.log(
    categories?.filter(
      (c) =>
        c.name?.trim().replaceAll(/\s+/g, "-")?.replaceAll("---", "-") ==
        propertyName?.trim().replaceAll(/\s+/g, "-")?.replaceAll("---", "-")
    )[0]?._id
  );
 useLayoutEffect(() => {
    setLoading(true);
    setLoadingProperties(true);
 
 }, [])
 useEffect(() => {
  removeLocalStorage()
  console.log(id);
}, []);
  const getAllProperties = async () => {
    // setLoading(true);
    setLoadingProperties(true)
    const { data } = await Axios.get(
      Url + `/api/home/property?pageSize=` + pageSize + "&pageNumber=" + page + "&category=" +
      id
    );
    console.log(data.properties);
    if (data.properties.length > 0) {
      // setproperties(data.properties);
      // setPageCount(data.count)
    // setLoading(false);
     setLoadingProperties(false);


     } else {
      setproperties([]);
    // setLoading(false);
    setLoadingProperties(false)


    }
  }

  useEffect(() => {
    console.log(location.state?.id);
    // getAllProperties();
    getDataOfCategoriesAndCities();
  }, []);
   
  useEffect(() => {
    // setTimeout(async () => {
    //   const { data } = await Axios.get(Url + `/api/dropdown/city`);
    //   setCities(data.cities);
    // }, 1);
    // getAllProperties()
    // getDataOfCategoriesAndCities()
  }, [page , id]);
  
  // const cities = JSON.parse(localStorage.getItem('cities'));
  // console.log("cities",cities);
  // console.log("cityId",cities?.filter(data => data.name?.trim()?.replaceAll(/\s+/g, '-')==name?.trim()?.replaceAll(/\s+/g, '-')));
  // let id =cities?.filter(data => data.name?.trim()?.replaceAll(/\s+/g, '-')==name?.trim()?.replaceAll(/\s+/g, '-'))[0]._id
  const observer = useRef();
  // const lastPropertyElementRef = useCallback(
  //   (node) => {
  //     console.log(node);
  //     if (observer.current) observer.current.disconnect();
  //     observer.current = new IntersectionObserver(async (entries) => {
  //       if (entries[0].isIntersecting && hasMore) {
  //         setLoading(true);
  //         const newPage = page + 1;
  //         setpage(newPage);
  //         const { data } = await Axios.get(
  //           Url + `/api/home/property?pageSize=` + pageSize + "&pageNumber=" + page +"&bedrooms=" +
  //           bedrooms +
  //           "&beds=" +
  //           beds +
  //           "&bathrooms=" +
  //           bathrooms +
  //           "&minPrice=" +
  //           sliderValue.at(0) +
  //           "&maxPrice=" +
  //           sliderValue.at(1) +
  //           "&category=" +
  //           id
  //         );
  //         const proper = [...properties];
  //         setPageCount(data.count)
  //         for (let i = 0; i < data.properties.length; i++) {
  //           proper.push(data.properties[i]);
  //         }
  //         if (data.properties.length === 0) setHasMore(false);
  //         setproperties(proper);
  //         setLoading(false);
  //       }
  //     });
  //     if (node) observer.current.observe(node);
  //   },
  //   [loading ,hasMore]
  // );
  const getInfoWindowString = (place) => `
    <div>
      <div style="font-size: 16px;">
        ${place.name}
      </div>
      <div style="font-size: 14px;">
        <span style="color: grey;">
        price: ${place.price}
        </span>
       
      </div>
     
    
    </div>`;
  const handleApiLoaded = (map, maps, places) => {
    const markers = [];
    const infowindows = [];
    places.forEach((place) => {
      markers.push(
        new maps.Marker({
          position: {
            lat: parseFloat(place.lat),
            lng: parseFloat(place.long),
          },
          map,
        })
      );
      infowindows.push(
        new maps.InfoWindow({
          content: getInfoWindowString(place),
        })
      );
    });

    markers.forEach((marker, i) => {
      marker.addListener("click", () => {
        infowindows[i].open(map, marker);
      });
    });
  };
  const getFilterData = async () => {
    if (type === "city") {
      if(!filterAcction){

        setLoadingFilter(true);
      }
      const { data } = await Axios.get(Url + `/api/home/city/${id}?pageSize=${pageSize}&pageNumber=${page}&bedrooms=${bedrooms}&beds=${beds}&bathrooms=${bathrooms}&minPrice=${sliderValue.at(0)}&maxPrice=${sliderValue.at(1)}`);
      console.log(data);
      setCity(propertyName.split("-").join(" - "));
      setpopuler(data.popular);
      setproperties(data.properties);
      setCount(data.count);
      setPageCount(data.count)
      setCityCount(data.count)
      setMap(data.map);
      setLoadingFilter(false);
    } else {
      setLoadingFilter(true);
      const { data } = await Axios.get(Url + `/api/home/category/${id}?pageSize=${pageSize}&pageNumber=${page}&bedrooms=${bedrooms}&beds=${beds}&bathrooms=${bathrooms}&minPrice=${sliderValue.at(0)}&maxPrice=${sliderValue.at(1)}` );
      setCity(propertyName.split("-").join(" - "));
      setpopuler(data.popular);
      setproperties(data.properties);
      setCount(data.count);
      setPageCount(data.count)
      setCityCount(data.count)
      setMap(data.map);
      setLoadingFilter(false);
    }
  };
  const getData = async (name) => {
    if(name == 'getFirstData'){

      setLoading(true);
    }else if(name == 'getFilterData'){
     setLoadingFilter(true);
    } else {
      setloadingPage(true);
    }
    if (type === "city") {
      const { data } = await Axios.get(Url + `/api/home/city/${id}?pageSize=${pageSize}&pageNumber=${page}`);
      console.log(data);
      setCity(propertyName.split("-").join(" - "));
      setpopuler(data.popular);
      setproperties(data.properties);
      setCount(data.count);
      setPageCount(data.count)
      setCityCount(data.count)
      setMap(data.map);
      setLoading(false);
      setLoadingFilter(false);
      setloadingPage(false);
    } else {
      const { data } = await Axios.get(Url + `/api/home/category/${id}?pageSize=${pageSize}&pageNumber=${page}` );
      setCity(propertyName.split("-").join(" - "));
      setpopuler(data.popular);
      setproperties(data.properties);
      setCount(data.count);
      setPageCount(data.count)
      setCityCount(data.count)
      setMap(data.map);
      setLoading(false);
      setLoadingFilter(false);
      setloadingPage(false);

    }
  };

  useEffect(() => {
    console.log(id);
    if (id) {
      if(isPageChange){

        getData("pageChange");
      } else {
        getData("getFirstData");
        
      }
    }
    
  }, [id,page ,isPageChange]);
  useEffect(() => {
    console.log(properties);
  }, [properties])

  const handleSliderChange = (event, newValue) => {
    setsliderValue(newValue);
  };
  const handleSort = async (typeSort) => {
    setloadingSort(true)
    if (type === "city") {
      const { data } = await Axios.get(Url + `/api/home/city/${id}?pageSize=${pageSize}&pageNumber=${page}&sort=${typeSort}`);
      console.log(data);
      setCity(propertyName.split("-").join(" - "));
      setpopuler(data.popular);
      setproperties(data.properties);
      setCount(data.count);
      setPageCount(data.count)
      setCityCount(data.count)
      setMap(data.map);
      setLoading(false);
      setLoadingFilter(false);
      setloadingPage(false);
      setloadingSort(false)
    } else {
      const { data } = await Axios.get(Url + `/api/home/category/${id}?pageSize=${pageSize}&pageNumber=${page}&sort=${type}` );
      setCity(propertyName.split("-").join(" - "));
      setpopuler(data.popular);
      setproperties(data.properties);
      setCount(data.count);
      setPageCount(data.count)
      setCityCount(data.count)
      setMap(data.map);
      setLoading(false);
      setLoadingFilter(false);
      setloadingPage(false);
      setloadingSort(false)


    }
  };
  const handleFilter = async () => {
    getFilterData()

  };
  const handleDisplayFilter = (filter) => {
    var newcurrentFilter;
    currentFilter === filter
      ? (newcurrentFilter = "")
      : (newcurrentFilter = filter);

    setscurrentFilter(newcurrentFilter);
  };
  const handleSelectFilter = (filter) => {
    var newselectedFilters;

    if (selectedFilters.filter((f) => f === filter).length !== 0) {
      newselectedFilters = selectedFilters.filter((f) => f != filter);
    } else {
      newselectedFilters = [...selectedFilters];
      newselectedFilters.push(filter);
    }

    setsSelectedFilters(newselectedFilters);
  };
  const handlePlus = (type) => {
    if (type === "beds") {
      const newBeds = beds + 1;
      setbeds(newBeds);
    } else if (type === "bedrooms") {
      const newBedRooms = bedrooms + 1;
      setbedrooms(newBedRooms);
    } else {
      const newBathrooms = bathrooms + 1;
      setbathrooms(newBathrooms);
    }
  };
  const handleMin = (type) => {
    if (type === "beds" && beds > 0) {
      const newBeds = beds - 1;
      setbeds(newBeds);
    } else if (type === "bedrooms" && bedrooms > 0) {
      const newBedRooms = bedrooms - 1;
      setbedrooms(newBedRooms);
    } else if (type === "bathrooms" && bathrooms > 0) {
      const newBathrooms = bathrooms - 1;
      setbathrooms(newBathrooms);
    }
  };
  const handleMinMax = ({ currentTarget: input }) => {
    if (input.name === "min") {
      const max = sliderValue.at(1);
      setsliderValue([parseInt(input.value), max]);
    } else {
      const min = sliderValue.at(0);
      setsliderValue([min, parseInt(input.value)]);
    }
  };

  const handleChange = (event, value) => {
    console.log(value);
    setisPageChange(true)
    setpage(value);
  };


  function toggleDisplay() {
    setDisplay(!display);
  }

  let displayValue = display ? "block" : "none";

  if (screenWidth >= 767) {
    displayValue = "block";
  }

  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const AnyReactComponent = ({ text }) => <div>{text}</div>;
  return (
    <React.Fragment>
      <SearchBar />
      <div className="container search-mr mb-5" style={{maxWidth:screenWidth <= 768?"90%":"100%",paddingRight:screenWidth <= 768&&0,paddingLeft:screenWidth <= 768&&0}} >
        <div className="row">
          <hr id="horizintal" className="hide-row" />
        </div>
        {loading? (
          <>
            <Box sx={{ display: "flex", margin: "100px auto" }}>
              <CircularProgress style={{ color: "#073448" }} />
            </Box>
          </>
        ) : (
          <>
            <div className="row form-img-container">
              <div className="col-sm-12 col-md-12 col-lg-12 properties2-img-container" style={{padding:0}}>
                <div
                  //className="row"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <div
                    //className="row"
                    className="prop-header"
                  >
                    <div
                      // className="col-12

                      // lebanon-city"
                      className="lebanon-city"
                    >
                      {type === "city" ? (
                        <span className="light-text blue-color prop-light">
                          Lebanon / {city}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="prop-header-sub">
                      <div
                        //className="col-sm-12 col-md-6 mb-4 city"
                        className="city"
                      >
                        <h5 className="semi-bold blue-color mb-3">{city}</h5>
                      </div>
                      <div
                      // className="col-sm-12 col-md-6"
                      >
                        <div className="row sort">
                          <div className="col-10 count-properties">
                            <span className="blue-color">
                              {pageCount} properties
                            </span>
                          </div>
                          <div className="col-2">
                            <Dropdown>
                              <Dropdown.Toggle
                                variant=""
                                id="dropdown-basic"
                                className="blue-color"
                                style={{paddingInline:screenWidth <= 768 &&"0"}}
                              >
                                sort{" "}
                                <i
                                  className="fa fa-chevron-down"
                                  aria-hidden="true"
                                ></i>
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item onClick={() => handleSort(0)}>
                                  Recommended
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => handleSort(1)}>
                                  Price:low to high
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => handleSort(2)}>
                                  Price:high to low
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="row mb-3"> */}

                  {/* </div> */}

                  {/* <div className="row "> */}
                  {/* <div className="col-12 popular-cities"> */}
                  {loadingFilter || loadingSort? 
                  <Box sx={{ display: "flex", margin: "30% auto" }}>
                  <CircularProgress style={{ color: "#073448" }} />
                </Box>
                :
                <>
                
                  {type === "city" ? (
                    populer.length > 0 && screenWidth > 767 ?(
                      <span
                        className={
                          populer.length > 0 && screenWidth > 767
                            ? "blue-color semi-bold-3"
                            : "blue-color semi-bold-3"
                        }
                        style={{margin:"10px 0px"}}
                      >
                        Popular places to stay in {city}
                      </span>
                    ):null
                  ) : (
                    populer.length > 0 && screenWidth > 767  ?
                    <span className="blue-color semi-bold-3" style={{margin:"10px 0"}} >
                      Popular {city}
                    </span> :
                    null
                  )}
                  {/* </div> */}
                  {/* </div> */}
                  {screenWidth <= 768 ? (
                    <>
                        {type === "city" ? (
                        populer.length > 0 && screenWidth <= 768 ?(
                          <span
                            className={
                              populer.length > 0 && screenWidth <= 768
                                ? "blue-color semi-bold-3"
                                : "blue-color semi-bold-3"
                            }
                            style={{margin:"10px 0px"}}
                          >
                            Popular places to stay in {city}
                          </span>
                        ):null
                      ) : (
                        populer.length > 0 && screenWidth <= 768 ?
                        <span className="blue-color semi-bold-3" style={{margin:"10px 0"}} >
                          Popular {city}
                        </span> :
                        null
                      )}
                      { populer.length > 0 &&


                    <div className="swiper-container">
                  
                      <Swiper
                        freeMode={true}
                        pagination={{
                          el: ".swiper-pagination-1",
                          clickable: true,
                        }}
                        navigation={{
                          prevEl: ".swiper-button-prev-1",
                          nextEl: ".swiper-button-next-1",
                        }}
                        modules={[Navigation]}
                        grabCursor={true}
                        breakpoints={{
                          0: {
                            slidesPerView: 2,
                            spaceBetween: 0,
                          },
                          500: {
                            slidesPerView: 2,
                            spaceBetween: 0,
                          },

                          991: {
                            slidesPerView: 3,
                            spaceBetween: 0,
                          },
                          1300: {
                            slidesPerView: 4,
                            spaceBetween: 0,
                          },
                          2560: {
                            slidesPerView: 6,
                            spaceBetween: 0,
                          },
                        }}
                      >
                        <div className="row">
                          {populer.map((property) => (
                            <SwiperSlide key={property.id}>
                              <div
                                className="col-sm-12 col-md-6 col-lg-6 col-xl-4 no-padding"
                                key={property._id}
                              >
                                <Card
                                  Card={property}
                                  type={"property"}
                                  city={city}
                                  prevType={type}
                                />
                              </div>
                            </SwiperSlide>
                          ))}
                        </div>

                    
                      </Swiper>

                      <>
                        <div className="swiper-button-prev swiper-button-prev-1"></div>
                        <div className="swiper-button-next swiper-button-next-1"></div>
                      </>
                    </div>
                      }
                     {populer?.length > 0 && <hr id="horizintal" className="my-hr" />}
                      {    properties.length > 0 && 
                      <>
                      
                      {properties.length > 0 && screenWidth < 767 ? (
                              <span
                                className={
                                  properties.length > 0 && screenWidth < 767
                                    ? "blue-color semi-bold-3"
                                    : "blue-color semi-bold-3"
                                }
                                style={{ margin: "10px 0px" }}
                              >
                                 places to stay in {city}
                              </span>
                            ) : null}
                    <div className="swiper-container">
                  
                  <Swiper
                    freeMode={true}
                    pagination={{
                      el: ".swiper-pagination-1",
                      clickable: true,
                    }}
                    navigation={{
                      prevEl: ".swiper-button-prev-1",
                      nextEl: ".swiper-button-next-1",
                    }}
                    modules={[Navigation]}
                    grabCursor={true}
                    breakpoints={{
                      0: {
                        slidesPerView: 2,
                        spaceBetween: 0,
                      },
                      500: {
                        slidesPerView: 2,
                        spaceBetween: 0,
                      },

                      991: {
                        slidesPerView: 3,
                        spaceBetween: 0,
                      },
                      1300: {
                        slidesPerView: 4,
                        spaceBetween: 0,
                      },
                      2560: {
                        slidesPerView: 6,
                        spaceBetween: 0,
                      },
                    }}
                  >
                         
                            <div className="row mt-3">
                              {properties.map((property, index) =>
                                properties.length === index + 1 ? (
                                  <SwiperSlide key={property.id}>
                                    <div
                                      className="col-sm-12 col-md-6 col-lg-6 col-xl-4 no-padding"
                                      // ref={lastPropertyElementRef}
                                      key={property._id}
                                    >
                                      <Card
                                        Card={property}
                                        type={"property"}
                                        city={city}
                                        prevType={type}
                                      />
                                    </div>
                                  </SwiperSlide>
                                ) : (
                                  <SwiperSlide key={property.id}>
                                    <div
                                      className="col-sm-12 col-md-6 col-lg-6 col-xl-4 no-padding"
                                      key={property._id}
                                    >
                                      <Card
                                        Card={property}
                                        type={"property"}
                                        city={city}
                                        prevType={type}
                                      />
                                    </div>
                                  </SwiperSlide>
                                )
                              )}
                             
                            </div>
                      </Swiper>
                      </div>
                      
                      </>
                      
                      }
                    </>
                  ) : 
                  (
                    <div className="">
                      {populer.length > 0 ?
                      
                      <div className="row " style={{marginLeft:0}}>
                      {populer.length == 0 && 
                        <>
                        <Box sx={{margin:"150px auto",width:"100%"}}>
                           <Typography>
                            <div className="blue-color semi-bold-3" style={{margin:"auto",textAlign:"center",color:"#ccc",display:"flex",alignItems:"center",gap:8,justifyContent:"center"}}><IoWarningOutline style={{fontSize:22}}/> No Data Found </div>
                           </Typography>
                        </Box>
                        </>}
                        {populer.map((property) => (
                          <div
                            className="col-sm-12 col-md-6 col-lg-6 col-xl-4 no-padding"
                            key={property._id}
                          >
                            <Card
                              Card={property}
                              type={"property"}
                              city={city}
                              prevType={type}
                            />
                          </div>
                        ))}
                      </div>
                      :
                      null
                    }
                     

                    {populer.length > 0 &&  <hr id="horizintal" />}

                      {properties.length > 0 && screenWidth > 767? (
                          <span
                            className={
                              properties.length > 0 && screenWidth > 767
                                ? "blue-color semi-bold-3"
                                : "blue-color semi-bold-3"
                            }
                            style={{ marginBlock: "10px" , marginLeft:"0px" }}
                          >
                          Places to stay in {city}
                          </span>
                        ) : null}
                        {properties.length > 0 && 
                        
                        <div className="row mt-3" style={{marginLeft:0}}>
                          {properties.length == 0 && 
                          <>
                          <Box sx={{margin:"150px auto",width:"100%"}}>
                            <Typography>
                              <div className="blue-color semi-bold-3" style={{margin:"auto",textAlign:"center",color:"#ccc",display:"flex",alignItems:"center",gap:8,justifyContent:"center"}}><IoWarningOutline style={{fontSize:22}}/> No Data Found </div>
                            </Typography>
                          </Box>
                          </>}
                          {loadingPage?<Box sx={{ display: "flex", margin: "100px auto" }}>
              <CircularProgress style={{ color: "#073448" }} />
                </Box>
            :properties.map((property, index) =>
                            // properties.length === index + 1 ? (
                              <div
                                className="col-sm-12 col-md-6 col-lg-6 col-xl-4 no-padding"
                                // ref={lastPropertyElementRef}
                                key={property._id}
                              >
                                <Card
                                  Card={property}
                                  type={"property"}
                                  city={city}
                                  prevType={type}
                                />
                              </div>
                            // ) : 
                            // (
                            //   <div
                            //     className="col-sm-12 col-md-6 col-lg-6 col-xl-4 no-padding"
                            //     key={property._id}
                            //   >
                            //     <Card
                            //       Card={property}
                            //       type={"property"}
                            //       city={city}
                            //       prevType={type}
                            //     />
                            //   </div>
                            // )
                          )}
                          {Math.round(pageCount/pageSize) > 0 && 
                          
                          <div style={{display:"flex",justifyContent:"center",width:"100%",alignItems:"center"}}>
                    
                          <Pagination  style={{margin:"0 auto",display:"flex",justifyContent:"center"}} count={Math.ceil(pageCount/pageSize)} page={page} onChange={handleChange} />
                          </div>
                        
                        }
                        </div>
                        
                        }

                        {((properties.length == 0  &&  populer.length == 0) && (!loading && !loadingProperties)) ?
                          <div style={{margin:"230px auto",textAlign:"center"}}>
                  
                            <Typography>
                            <div className="blue-color semi-bold-3" style={{margin:"auto",textAlign:"center",color:"#ccc",display:"flex",alignItems:"center",gap:8,justifyContent:"center"}}><IoWarningOutline style={{fontSize:22}}/> No Data Found </div>
                           </Typography>

                          </div>
                          :null
                        }

                      <></>
                    </div>
                  )}
                
                </>

                  }

                </div>

                <div className="map-filters">
                  <div id="map" style={{width:screenWidth <= 768 && "100%"}}>
                    {places.length > 0 && (
                      <GoogleMap
                        bootstrapURLKeys={{
                          key: process.env.REACT_APP_MAP_KEY,
                        }}
                        center={{
                          lat:places[0]?.lat? parseFloat(places[0]?.lat):0,
                          lng:places[0]?.long? parseFloat(places[0]?.long):0,
                        }}
                        defaultZoom={defaultProps.zoom}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={({ map, maps }) =>
                          handleApiLoaded(map, maps, places)
                        }
                      />
                    )}
                  </div>

                  <div className="pro-filters" style={{width:screenWidth <= 768 && "100%",overflow:screenWidth <= 768 &&"hidden"}}>
                    <h5 className="filter-h upper mb-3">Refine Results</h5>
                    {/* <div className="row">
<div className="col-10">
<h6 className='filter-h '>Type of places</h6>
</div>

<div className="col-2 filter-chevron">
<a onClick={()=>handleDisplayFilter("Type of places")}> <i className="fa-solid fa-chevron-down"></i></a>
</div>
</div> */}
                    {/* <div className="filter-props">
<div className={currentFilter==="Type of places"?"list-group":"list-group-h"} >

<a onClick={()=>handleSelectFilter("Entire Place")} className={selectedFilters.filter(f=>f==="Entire Place").length!==0 ? "list-group-item list-group-item-action active-filter" : "list-group-item list-group-item-action "} >Entire Place</a>
<a onClick={()=>handleSelectFilter("Shared Room")} className={selectedFilters.filter(f=>f==="Shared Room").length!==0 ? "list-group-item list-group-item-action active-filter" : "list-group-item list-group-item-action "}>Shared Room</a>
<a onClick={()=>handleSelectFilter("Private Room")} className={selectedFilters.filter(f=>f==="Private Room").length!==0 ? "list-group-item list-group-item-action active-filter" : "list-group-item list-group-item-action "}>Private Room</a>
</div>

<hr id='horizintal'/>
</div> */}
                    <div className="row mb-3">
                      <div className="col-10">
                        <h6 className="filter-h ">Rooms & Beds</h6>
                      </div>

                      <div className="col-2 filter-chevron">
                        <a onClick={() => handleDisplayFilter("Rooms & Beds")}>
                          {" "}
                          <i className="fa-solid fa-chevron-down"></i>
                        </a>
                      </div>
                    </div>
                    <div className="filter-props">
                      <div
                        className={
                          currentFilter === "Rooms & Beds"
                            ? "list-group"
                            : "list-group-h"
                        }
                      >
                        <div className="counter-row">
                          <div className="row">
                            <div className="col-7">
                              <div className="counter-span">
                                <span>{bedrooms} Bedroms</span>
                              </div>
                            </div>
                            <div className="col-5">
                              <div className="row">
                                <div className="col-6">
                                  <button
                                    id="counter-btn"
                                    onClick={() => handlePlus("bedrooms")}
                                  >
                                    +
                                  </button>
                                </div>

                                <div className="col-2">
                                  <button
                                    id="counter-btn"
                                    disabled={bedrooms === 0}
                                    onClick={() => handleMin("bedrooms")}
                                  >
                                    -
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="counter-row">
                          <div className="row">
                            <div className="col-7">
                              <div className="counter-span">
                                <span>{beds} Beds</span>
                              </div>
                            </div>
                            <div className="col-5">
                              <div className="row">
                                <div className="col-6">
                                  <button
                                    id="counter-btn"
                                    onClick={() => handlePlus("beds")}
                                  >
                                    +
                                  </button>
                                </div>

                                <div className="col-2">
                                  <button
                                    id="counter-btn"
                                    disabled={beds === 0}
                                    onClick={() => handleMin("beds")}
                                  >
                                    -
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="counter-row">
                          <div className="row">
                            <div className="col-7">
                              <div className="counter-span">
                                <span>{bathrooms} Bathrooms</span>
                              </div>
                            </div>
                            <div className="col-5">
                              <div className="row">
                                <div className="col-6">
                                  <button
                                    id="counter-btn"
                                    onClick={() => handlePlus("bathrooms")}
                                  >
                                    +
                                  </button>
                                </div>

                                <div className="col-2">
                                  <button
                                    id="counter-btn"
                                    disabled={bathrooms === 0}
                                    onClick={() => handleMin("bathrooms")}
                                  >
                                    -
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <hr id="horizintal" />
                    </div>
                    {/* <div className="row">
<div className="col-10">
<h6 className='filter-h '>Property type</h6>
</div>

<div className="col-2 filter-chevron">
<a onClick={()=>handleDisplayFilter("Property type")}> <i className="fa-solid fa-chevron-down"></i></a>
</div>
</div> */}
                    {/* <div className="filter-props">
<div className={currentFilter==="Property type"?"list-group":"list-group-h"} >

<a onClick={()=>handleSelectFilter("House")} className={selectedFilters.filter(f=>f==="House").length!==0 ? "list-group-item list-group-item-action active-filter" : "list-group-item list-group-item-action "} > <img className="mr-2" src={House} alt="" />House</a>
<a onClick={()=>handleSelectFilter("Apartment")} className={selectedFilters.filter(f=>f==="Apartment").length!==0 ? "list-group-item list-group-item-action active-filter" : "list-group-item list-group-item-action "} ><img className="mr-2" src={Apartment} alt="" />Apartment</a>
<a onClick={()=>handleSelectFilter("Guesthouse")} className={selectedFilters.filter(f=>f==="Guesthouse").length!==0 ? "list-group-item list-group-item-action active-filter" : "list-group-item list-group-item-action "} ><img className="mr-2" src={Guesthouse} alt="" />Guesthouse</a>
<a onClick={()=>handleSelectFilter("Hotel")} className={selectedFilters.filter(f=>f==="Hotel").length!==0 ? "list-group-item list-group-item-action active-filter" : "list-group-item list-group-item-action "} > <img className="mr-2" src={Hotel} alt="" />Hotel</a>

</div>

<hr id='horizintal'/>
</div> */}
                    <div className="row">
                      <div className="col-10">
                        <h6 className="filter-h">Price</h6>
                      </div>
                      <div className="col-2 filter-chevron"></div>
                    </div>

                    <div className="price-slider">
                      <Slider
                        getAriaLabel={() => "Temperature range"}
                        value={sliderValue}
                        onChange={handleSliderChange}
                        valueLabelDisplay="auto"
                        min={minPrice}
                        max={maxPrice}
                      />
                    </div>
                    <div className="d-flex mt-3">
                      <div className="price-text mr-1">
                        <span>Price</span>
                      </div>
                      <div className="mr-3 d-flex">
                        <input
                          name="min"
                          type="number"
                          className="price-input"
                          placeholder={sliderValue.at(0) + " $"}
                          onChange={handleMinMax}
                        ></input>
                        <span className="price-text ">to</span>
                      </div>

                      <div className="">
                        <input
                          name="max"
                          type="number"
                          className="price-input"
                          placeholder={sliderValue.at(1) + " $"}
                          onChange={handleMinMax}
                        ></input>
                      </div>

                      <div className="">
                        <button
                        disabled={loadingFilter}
                          className=" scrollto filter-btn"
                          onClick={handleFilter}
                        >
                          {loadingFilter?<CircularProgress size={15} style={{ color: "#fff" }} />:"Filter"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <SearchFooter />
    </React.Fragment>
  );
}

export default Properties;
