import React, { Component } from 'react'
import axios from 'axios'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import { Url } from "../../..";
import { Row } from 'react-bootstrap'
class Calendar extends Component {
  state = {
    opened: false,
    openedDate: false,
    delete: '',
    calendar: [],
    pageSize: 8,
    currentPage: 1,
    searchWord: '',
    count: 0,
    data: { guest: '', property: '', endDate: '' },

    display: true,
    errors: {},
    fetched: false,
    edit: false,
    id: '',
    eventId: '',
    event: '',
    startDate: '',
    openedAdd: false,
    openedBlock: false,
    guests: [],
    properties: [],
    endDate: '',
    property: '',
    notes: '',
    selectedProperty: '',
    openedDelete: false,
  }


  // handleOpen = async (clickInfo) => {
  //   const { data } = await axios.get(
  //     Url+'/api/booking/' + clickInfo.event._def.publicId
  //   )
  //   if (data.booking.recordType === 'booking') {
  //     this.setState({
  //       opened: true,
  //       event: data.booking,
  //       delete: clickInfo.event._def.publicId,
  //       eventId: clickInfo,
  //     })
  //   } else
  //     this.setState({
  //       openedDelete: true,
  //       event: data.booking,
  //       delete: clickInfo.event._def.publicId,
  //       eventId: clickInfo,
  //     })
  // }
  // handleEndDate = ({ currentTarget: input }) => {
  //   this.setState({ endDate: input.value })
  // }
  // handleProperty = ({ currentTarget: input }) => {
  //   this.setState({ property: input.value })
  // }
  // handleNotes = ({ currentTarget: input }) => {
  //   this.setState({ notes: input.value })
  // }
  // handleSelectProperty = async ({ currentTarget: input }) => {
  //   const { data } = await axios.get(
  //     Url+'/api/calendar?property=' + input.value
  //   )
  //   const events = []
  //   data.calendar.forEach((ele) => {
  //     let event = {}
  //     let start = ele.startDate.split('T')[0]
  //     let end = ele.endDate.split('T')[0]
  //     if (ele.recordType === 'booking')
  //       event = { id: ele._id, title: ele.notes, start: start, end: end }
  //     else
  //       event = {
  //         id: ele._id,
  //         title: ele.notes,
  //         start: start,
  //         end: end,
  //         color: 'red',
  //       }
  //     events.push(event)
  //   })
  //   const dataObj = { guest: '', property: input.value, endDate: '' }
  //   this.setState({
  //     calendar: events,
  //     selectedProperty: input.value,
  //     data: dataObj,
  //     property: input.value,
  //   })
  // }
  // handleSubmitBlock = async () => {
  //   if (this.state.endDate === '') {
  //     return
  //   }
  //   if (this.state.property === '') {
  //     return
  //   }
  //   if (this.state.notes === '') {
  //     return
  //   }
  //   try {
  //     const obj = {
  //       property: this.state.property,
  //       startDate: this.state.startDate,
  //       endDate: this.state.endDate,
  //       notes: this.state.notes,
  //     }
  //     const { data } = await axios.post(Url+'/api/calendar/block', obj)
  //     let start = data.blocked.startDate.split('T')[0]
  //     let end = data.blocked.endDate.split('T')[0]
  //     let calendarApi = this.state.eventId.view.calendar
  //     calendarApi.unselect()
  //     calendarApi.addEvent({
  //       id: data.blocked._id,
  //       title:data.blocked.notes,
  //       start: start,
  //       end: end,
  //       color: 'red',
  //     })
  //     this.setState({
  //       property: '',
  //       endDate: '',
  //       notes: '',
  //       startDate: '',
  //       openedBlock: false,
  //       eventId: '',
  //     })
  //   } catch (ex) {
  //   }
  // }
  // handleClose = () => {
  //   this.setState({ opened: false })
  // }
  // handleCloseDate = () => {
  //   this.setState({ openedDate: false })
  // }
  // handleCloseAdd = () => {
  //   this.setState({ openedAdd: false })
  // }
  // handleCloseBlock = () => {
  //   this.setState({ openedBlock: false })
  // }
  // handleCloseDelete = () => {
  //   this.setState({ openedDelete: false })
  // }
  // style = {
  //   position: 'absolute',
  //   top: '50%',
  //   left: '50%',
  //   transform: 'translate(-50%, -50%)',
  //   width: 400,
  //   bgcolor: 'background.paper',
  //   border: '2px solid #000',
  //   boxShadow: 24,
  //   p: 4,
  // }
  // styleDate = {
  //   position: 'absolute',
  //   top: '50%',
  //   left: '50%',
  //   transform: 'translate(-50%, -50%)',
  //   width: 400,
  //   bgcolor: 'background.paper',
  //   border: '2px solid #000',
  //   boxShadow: 24,
  //   p: 4,
  //   textAlign: 'center',
  // }
  // handleDisplay = () => {
  //   const display = !this.state.display
  //   this.setState({ display: display })
  // }
  // validate = () => {
  //   const options = { abortEarly: false }
  //   const { error } = Joi.validate(this.state.data, this.schema, options)
  //   if (!error) return null
  //   const errors = {}
  //   for (let item of error.details) errors[item.path[0]] = item.message
  //   return errors
  // }
  // validateProperty = ({ name, value }) => {
  //   const obj = { [name]: value }
  //   const schema = { [name]: this.schema[name] }
  //   const { error } = Joi.validate(obj, schema)
  //   return error ? error.details[0].message : null
  // }
  // onFileChange = (event) => {
  //   this.setState({ image: event.target.files[0] })
  // }
  // handleChange = ({ currentTarget: input }) => {
  //   const errors = { ...this.state.errors }
  //   const errorMessage = this.validateProperty(input)
  //   if (errorMessage) errors[input.name] = errorMessage
  //   else delete errors[input.name]
  //   const data = { ...this.state.data }
  //   data[input.name] = input.value
  //   this.setState({ data, errors })
  // }
  getCalendar = async () => {
    const { data } = await axios.get(Url+'/api/calendar')
    const events = []
    data.calendar.forEach((ele) => {
      let event = {}
      let start = ele.startDate.split('T')[0]
      let end = ele.endDate.split('T')[0]
      if (ele.recordType === 'booking')
        event = { id: ele._id, title: "", start: start, end: end }
      else
        event = {
          id: ele._id,
          title: "",
          start: start,
          end: end,
          color: 'red',
        }
      events.push(event)
    })

    this.setState({ calendar: events })
  }
  async componentDidMount() {
    this.getCalendar()
    // if (localStorage.getItem('token') === null) window.location = '/login'
    // else {
      // var { data } = await axios.get(Url+'/api/dropdown/guest')
      // this.setState({ guests: data.guests })
      // var { data } = await axios.get(Url+'/api/dropdown/property')
      // this.setState({ properties: data.properties })
    // }
  }
  // handleSubmit = async () => {
  //   try {
  //     const obj = {
  //       guest: this.state.data.guest,
  //       property: this.state.data.property,
  //       startDate: this.state.startDate,
  //       endDate: this.state.data.endDate,
  //     }
  //     const { data } = await axios.post(Url+'/api/calendar/booking', obj)
  //     let start = data.booking.startDate.split('T')[0]
  //     let end = data.booking.endDate.split('T')[0]
  //     let calendarApi = this.state.eventId.view.calendar
  //     calendarApi.unselect()
  //     calendarApi.addEvent({
  //       id: data.booking._id,
  //       title: data.booking.notes,
  //       start: start,
  //       end: end,
  //     })
  //     this.setState({
  //       data: { guest: '', property: '', endDate: '' },
  //       startDate: '',
  //       openedAdd: false,
  //       eventId: '',
  //     })
  //   } catch (ex) {
  //   }
  // }
  // handleDateClick = (clickInfo) => {
  //   const date = clickInfo.date.toISOString().split('T')[0]
  //   this.setState({ startDate: date, openedDate: true, eventId: clickInfo })
  // }
  // handleAddBookingModal = async () => {
  //   this.setState({ openedDate: false, openedAdd: true })
  // }
  // handleBlockModal = async () => {
  //   this.setState({ openedDate: false, openedBlock: true })
  // }
  // handleDeleteBlock = async () => {
  //   try {
  //     await axios.delete(Url+'/api/calendar/block/' + this.state.delete)
  //     const events = this.state.calendar.filter((c) => c.id != this.state.delete)
  //     this.state.eventId.event.remove()
  //     this.setState({
  //       events: events,
  //       delete: '',
  //       openedDelete: false,
  //       event: '',
  //       eventId: '',
  //     })
  //   } catch (ex) {
  //   }
  // }
  // handleDelete = async () => {
  //   try {
  //     await axios.delete(Url+'/api/calendar/booking/' + this.state.delete)
  //     const events = this.state.calendar.filter((c) => c.id != this.state.delete)
  //     this.state.eventId.event.remove()
  //     this.setState({
  //       events: events,
  //       delete: '',
  //       opened: false,
  //       event: '',
  //       eventId: '',
  //     })
  //   } catch (ex) {
  //   }
  // }
  render() {
    // const { data } = this.state
    return (
      <React.Fragment>
        {/*<Modal
          open={this.state.openedDelete}
          onClose={this.handleCloseDelete}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={this.styleDate}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Delete Blocking
            </Typography>
            <Button style={{ color: 'red', textAlign: 'center' }} onClick={this.handleDeleteBlock}>
              Submit
            </Button>
          </Box>
        </Modal>
        <Modal
          open={this.state.openedAdd}
          onClose={this.handleCloseAdd}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={this.style}>
            <CForm>
              <div className="row">
                <div className="col-6">
                  <div className="mb-3">
                    <div className="form-group">
                      <CFormLabel htmlFor="guest">Guest</CFormLabel>
                      <select
                        name="guest"
                        id="guest"
                        className="form-control"
                        onChange={this.handleChange}
                      >
                        <option value="" selected={this.state.data.guest === ''}>
                          Select Guest
                        </option>
                        {this.state.guests.map((g) => (
                          <option
                            value={g._id}
                            key={g._id}
                            selected={this.state.data.guest === g._id}
                          >
                            {g.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    {this.state.errors.guest && (
                      <div className="alert alert-danger">{this.state.errors.guest}</div>
                    )}
                  </div>
                </div>
                <div className="col-6">
                  <div className="mb-3">
                    <div className="form-group">
                      <CFormLabel htmlFor="property">Property</CFormLabel>
                      <select
                        name="property"
                        id="property"
                        className="form-control"
                        onChange={this.handleChange}
                        disabled={this.state.selectedProperty !== ''}
                      >
                        <option
                          value=""
                          selected={
                            this.state.selectedProperty === ''
                              ? this.state.data.property === ''
                              : false
                          }
                        >
                          Select Property
                        </option>
                        {this.state.properties.map((p) => (
                          <option
                            value={p._id}
                            key={p._id}
                            selected={
                              this.state.selectedProperty !== ''
                                ? this.state.selectedProperty === p._id
                                : this.state.data.property === p._id
                            }
                          >
                            {p.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    {this.state.errors.property && (
                      <div className="alert alert-danger">{this.state.errors.property}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="mb-3">
                    <CFormLabel htmlFor="startDate">Start Date</CFormLabel>
                    <CFormInput
                      type="date"
                      id="startDate"
                      name="startDate"
                      value={this.state.startDate}
                      readOnly
                    />
                    {this.state.errors.startDate && (
                      <div className="alert alert-danger">{this.state.errors.startDate}</div>
                    )}
                  </div>
                </div>
                <div className="col-6">
                  <div className="mb-3">
                    <CFormLabel htmlFor="endDate">End Date</CFormLabel>
                    <CFormInput
                      type="date"
                      id="endDate"
                      name="endDate"
                      value={data['endDate']}
                      onChange={this.handleChange}
                    />
                    {this.state.errors.endDate && (
                      <div className="alert alert-danger">{this.state.errors.endDate}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-auto">
                <CButton disabled={this.validate()} className="mb-3" onClick={this.handleSubmit}>
                  Add Booking
                </CButton>
              </div>
            </CForm>
          </Box>
        </Modal>
        <Modal
          open={this.state.openedBlock}
          onClose={this.handleCloseBlock}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={this.style}>
            <CForm>
              <div className="row">
                <div className="col-6">
                  <div className="mb-3">
                    <div className="form-group">
                      <CFormLabel htmlFor="property">Property</CFormLabel>
                      <select
                        name="property"
                        id="property"
                        className="form-control"
                        onChange={this.handleProperty}
                        value={this.state.property}
                        disabled={this.state.selectedProperty !== ''}
                      >
                        <option value="" selected={this.state.property === ''}>
                          Select Property
                        </option>
                        {this.state.properties.map((p) => (
                          <option
                            value={p._id}
                            key={p._id}
                            selected={this.state.property === p._id}
                          >
                            {p.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="mb-3">
                    <CFormLabel htmlFor="notes">Notes</CFormLabel>
                    <CFormInput
                      type="text"
                      id="notes"
                      name="notes"
                      value={this.state.notes}
                      onChange={this.handleNotes}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="mb-3">
                    <CFormLabel htmlFor="startDate">Start Date</CFormLabel>
                    <CFormInput
                      type="date"
                      id="startDate"
                      name="startDate"
                      value={this.state.startDate}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="mb-3">
                    <CFormLabel htmlFor="endDate">End Date</CFormLabel>
                    <CFormInput
                      type="date"
                      id="endDate"
                      name="endDate"
                      value={this.state.endDate}
                      onChange={this.handleEndDate}
                    />
                  </div>
                </div>
              </div>
              <div className="col-auto">
                <CButton className="mb-3" onClick={this.handleSubmitBlock}>
                  Submit
                </CButton>
              </div>
            </CForm>
          </Box>
        </Modal>
        <Modal
          open={this.state.openedDate}
          onClose={this.handleCloseDate}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={this.styleDate}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{ textAlign: 'center' }}
            >
              {this.state.startDate}
            </Typography>
            <Button style={{ textAlign: 'center' }} onClick={this.handleAddBookingModal}>
              Booking
            </Button>
            <Button style={{ color: 'red', textAlign: 'center' }} onClick={this.handleBlockModal}>
              Blocked Date
            </Button>
          </Box>
        </Modal>
        <Modal
          open={this.state.opened}
          onClose={this.handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={this.style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{ textAlign: 'center' }}
            >
              Booking Information
            </Typography>

            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Guest:{' '}
              {this.state.event !== '' && this.state.opened ? this.state.event.guest.name : ''}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Property:{' '}
              {this.state.event !== '' && this.state.opened
                ? this.state.event.property.name.en
                : ''}
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h6"
              style={{ textAlign: 'center', marginTop: '10px' }}
            >
              Price Details
            </Typography>
            {this.state.event !== '' && this.state.opened
              ? this.state.event.priceDetails.map((date) => (
                  <Typography id="modal-modal-description" sx={{ mt: 2 }} key={date._id}>
                    {`${new Date(date.date).toLocaleDateString()}`}: {date.price}$
                  </Typography>
                ))
              : ''}
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2 }}
              style={{ marginBottom: '10px' }}
            >
              Total: {this.state.event !== '' && this.state.opened ? this.state.event.total : ''}$
            </Typography>
            <Button style={{ color: 'red', textAlign: 'center' }} onClick={this.handleDelete}>
              Delete
            </Button>
          </Box>
        </Modal> */}
        {/* <CRow>
          <div className="col-2">
            <CFormLabel htmlFor="property">Select Property</CFormLabel>

            <select
              className="form-select"
              size="15"
              aria-label="size 3 select example"
              onChange={this.handleSelectProperty}
            >
              <option value="">All Properties</option>
              {this.state.properties.map((p) => (
                <option value={p._id}>{p.name}</option>
              ))}
            </select>
          </div> */}
        
          <div className="col-12">
            <FullCalendar
             
              plugins={[dayGridPlugin, interactionPlugin]}
              initialView="dayGridMonth"
              events={this.state.calendar}
              eventClick={this.handleOpen}
              editable
              dateClick={this.handleDateClick}
            />
          </div>

         
        {/* </CRow> */}
      </React.Fragment>
    )
  }
}

export default Calendar
