import React, { Component } from "react";
import IM from "../../../images/IMG_3178.jpg";
import Card from "../card/card";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { FreeMode } from "swiper";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import { useSwiper } from "swiper/react";
import "./slider.css";
function Slider(props) {
  return (
    <Swiper
      freeMode={true}
      navigation={true}
      modules={[Navigation]}
      grabCursor={true}
      breakpoints={{
        0: {
          slidesPerView: 2,
          spaceBetween: 0,
        },
        500: {
          slidesPerView: 2,
          spaceBetween: 0,
        },

        991: {
          slidesPerView: 3,
          spaceBetween: 0,
        },
        1300: {
          slidesPerView: 4,
          spaceBetween: 0,
        },
        2560: {
          slidesPerView: 6,
          spaceBetween: 0,
        },
      }}
    >
      {props.data?.map((card, index) => (
        <SwiperSlide key={index}>
          <Card Card={card} type={props.type} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export default Slider;
